<template>
    <div>
        <!--<div class="text-center">
            <img src="./../assets/logo.png" class="img-fluid" alt="Logo">
        </div>-->

        <div class="row">
            <!--<div class="col">
                <img src="./../assets/check_in2.png" class="img-fluid" alt="Check-in">
            </div>
            <div class="col">
                <img src="./../assets/logo.png" class="img-fluid" alt="Logo">
            </div>-->

            <!--<div class="col-sm-2 offset-1">
                <img src="./../assets/check_in2.png" class="img-fluid" alt="Check-in">
            </div>
            <div class="col-sm-2 offset-2">
                <img src="./../assets/logo.png" class="img-fluid" alt="Logo">
            </div>-->

            <div class="col-2 offset-1">
                <img src="./../../assets/check_in.png" class="img-fluid" alt="Check-in">
            </div>
            <div class="col-2 offset-2">
                <img src="./../../assets/logo.png" class="img-fluid" alt="Logo">
            </div>
        </div>

        <div class="row mb-3">
            <div class="col">
                <h4>
                    <strong>
                        RANCH JESTŘEBICE
                        <br>
                        Jestřebice 4, 257 89 Heřmaničky, Česká republika
                        <br>
                        +420 703 140 630, info@ranchjestrebice.cz
                    </strong>
                </h4>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col">
                {{ $t("Proč vyplnit údaje předem? Ušetříte svůj čas při pobytu, po příjezdu nemusíte nic složitě hledat, zjišťovat a sepisovat.") }}
            </div>
        </div>

        <div class="row mt-2 mb-3">
            <div class="col">
                <div class="card text-dark bg-light mx-auto" style="width: 25rem;">
                    <div class="card-body">
                        <h5 class="card-title">{{ $t("Rezervace číslo") }}: {{ rezervace_data.rezervace.cislo }}</h5>
                                            <!--<h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>-->
                        <p class="card-text" style="text-align: left;">
                            {{ $t("Pokoje") }}: {{ pokoje }}
                            <br>
                            {{ $t("Pobyt") }}: {{ pobyt }}
                            <br>
                            {{ $t("Kontaktní osoba") }}: {{ kontaktni_osoba.jmeno }} {{ kontaktni_osoba.prijmeni }}
                            <br>
                            {{ $t("Hostů celkem") }}: {{ rezervace_data.rezervace.dospeli + rezervace_data.rezervace.deti || 0 }}
                        </p>
                                            <!--<a href="#" class="card-link">Card link</a>
                                            <a href="#" class="card-link">Another link</a>-->
                    </div>
                </div>
            </div>
        </div>

        <!--<div class="row mt-5">
            <div class="col text-left">
                <b-button
                    size="sm"
                    variant="primary"
                    @click="showFormGuestDetail(null)"
                >
                    {{ $t("Přidat hosta") }}
                </b-button>
            </div>
        </div>-->

        <div v-show="zobrazit_rezervaci">
            <b-table
                bordered
                class="mt-2"
                :fields="dtGuests.fields"
                hover
                :items="rezervace_data.hoste"
                responsive
                small
                stacked="md"
                OLDtbody-tr-class="table-success"
            >
                <template #cell(actions)="row">
                    <b-button
                        size="sm"
                        variant="primary"
                        @click="showFormGuestDetail(row)"
                    >
                        {{ $t("Přidat") }}
                    </b-button>
                    <!--&nbsp;
                    <b-button
                        size="sm"
                        variant="primary"
                        @click="showConfirmFormDeleteGuest(row)"
                    >
                        {{ $t("Smazat") }}
                    </b-button>-->
                </template>
            </b-table>

            <b-modal
                centered
                footer-bg-variant="light"
                header-bg-variant="light"
                hide-footer
                hide-header-close
                scrollable
                size="lg"
                ref="modal-guest-detail"
                :title="$t('Detail hosta')"
            >
                <div class="d-block text-center">
                    <GuestDetail />
                </div>

                <b-button
                    class="mt-3"
                    size="sm"
                    variant="primary"
                    @click="saveGuest"
                >
                    &nbsp;&nbsp;{{ $t("Odeslat") }}&nbsp;&nbsp;
                </b-button>
                &nbsp;
                <b-button
                    class="mt-3"
                    size="sm"
                    variant="secondary"
                    @click="hideModal"
                >
                    {{ $t("Zrušit") }}
                </b-button>
                
            </b-modal>
        </div>
        <div v-show="!zobrazit_rezervaci">
            <h4>
                <strong>
                    {{ $t("Rezervace již byla uzavřena.") }}
                </strong>
            </h4>
        </div>

    </div>
</template>

<script>
import { createNotice } from '@/lib/gms/error.js'
import { deepCopy } from '@/lib/gms/utils.js'
import { state, mutations } from '@/store/index.js'
import * as constants from '@/store/constants.js'
import { defaultsRezervaceHost } from '@/store/rezervace_host.js'
import GuestDetail from './GuestDetail.vue'

export default {
    components: {
        GuestDetail
    },

    computed: {
        host_data( ) {
            return state.host_data
        },

        kontaktni_osoba( ) {
            if (this.rezervace_data.hoste[0] != undefined) {
                return this.rezervace_data.hoste[0]
            } else {
                return deepCopy(defaultsRezervaceHost.rezervace_host)
            }
        },

        pobyt( ) {
            // https://www.codegrepper.com/code-examples/javascript/create+date+from+iso+string+javascript
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString

            if (this.rezervace_data.rezervace.dat_cas_od != null && this.rezervace_data.rezervace.dat_cas_do != null) {
                return new Date(this.rezervace_data.rezervace.dat_cas_od).toLocaleString('cs-CZ')
                    + " - "
                    + new Date(this.rezervace_data.rezervace.dat_cas_do).toLocaleString('cs-CZ')
            } else return ""
        },

        pokoje( ) {
            return (this.rezervace_data.pokoje.length > 0 ? this.rezervace_data.pokoje[0].nazev : "")
        },

        rezervace_data( ) {
            return state.rezervace_data
        },

        zobrazit_rezervaci( ) {
            return state.rezervace_data.rezervace.rezervace_stav_id == constants.REZERVACE_STAV_OPCE
                || state.rezervace_data.rezervace.rezervace_stav_id == constants.REZERVACE_STAV_POTVRZENO
                //|| true // TODO Zakomentovat
        }
    },

    data( ) {
        return {
            dtGuests: {
                fields: [
                    {
                        key: "actions",
                        label: this.$t("Host"),
                        tdClass: ["text-center", "fw-bold"],
                        thClass: "bg-light"
                    },
                    {
                        key: "jmeno",
                        label: this.$t("Jméno"),
                        tdClass: ["text-center", "fw-bold"],
                        thClass: "bg-light"
                    },
                    {
                        key: "prijmeni",
                        label: this.$t("Příjmení"),
                        tdClass: ["text-center", "fw-bold"],
                        thClass: "bg-light"
                    },
                    {
                        key: "ulice",
                        label: this.$t("Ulice"),
                        tdClass: ["text-center", "fw-bold"],
                        thClass: "bg-light"
                    },
                    /*{
                        key: "cislo_popisne",
                        label: "Č. p.",
                        tdClass: ["text-left", "fw-bold"],
                        thClass: "bg-light"
                    },*/
                    {
                        key: "mesto",
                        label: this.$t("Město"),
                        tdClass: ["text-center", "fw-bold"],
                        thClass: "bg-light"
                    },
                    {
                        key: "psc",
                        label: this.$t("PSČ"),
                        tdClass: ["text-center", "fw-bold"],
                        thClass: "bg-light"
                    },
                    {
                        key: "zeme_nazev",
                        label: this.$t("Země"),
                        tdClass: ["text-center", "fw-bold"],
                        thClass: "bg-light"
                    },
                    {
                        formatter: (value/*, key, item*/) => {
                            if (value != null) {
                                let locale = mutations.getLocale( )

                                const d = new Date(value)
                                
                                let text = d.toLocaleString(
                                    locale,
                                    { /*weekday: 'short', */year: 'numeric', month: 'numeric', day: 'numeric' }
                                )

                                return text
                            } else return ""
                        },
                        key: "d_narozeni",
                        label: this.$t("Datum narození"),
                        tdClass: ["text-center", "fw-bold"],
                        thClass: "bg-light"
                    },
                    {
                        key: "pokoj_nazev",
                        label: this.$t("Pokoj"),
                        tdClass: ["text-center", "fw-bold"],
                        thClass: "bg-light"
                    }
                    
                    /*,
                    {
                        formatter: (value, key, item) => {
                            return value ? 'Yes' : 'No'
                        },
                        key: "osoba_id",
                        label: "aaa",
                        tdClass: "text-left",
                        thClass: "bg-light"
                    }*/
                ]
            }
        }
    },

    methods: {
        hideModal( ) {
            this.$refs['modal-guest-detail'].hide( )
        },

        showConfirmFormDeleteGuest(row) {
            //console.log("showConfirmFormDeleteGuest", row)

            this.boxTwo = ''
            this.$bvModal.msgBoxConfirm(this.$t("Opravdu chcete odstranit hosta?"), {
                title: '',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Ok',
                cancelTitle: this.$t("Zrušit"),
                footerClass: 'p-2',
                hideHeaderClose: true,
                centered: true
            })
                .then(value => {
                    if (value == true) {
                        console.log("Delete guest ...", row)
                    }
                })
                .catch(err => {
                    console.error(err)
                })

        },

        showFormGuestDetail(row) {
            let hostData = deepCopy(defaultsRezervaceHost.rezervace_host)

            if (row != null) hostData = deepCopy(row.item)

            mutations.setHostDetailData(hostData)

            state.host_data_errors = deepCopy(defaultsRezervaceHost.rezervace_host)

            this.$refs['modal-guest-detail'].show( )
        },

        saveGuest( ) {
            mutations.createOrUpdateHost(this.host_data)
                .then(/*response*/ ( ) => {
                    createNotice(this.$t("Úspěšně uloženo"))

                    this.$refs['modal-guest-detail'].hide( )

                    mutations.readRezervace(state.request_rezervace_id)
                })
                .catch(errors => {
                    mutations.processHostDataErrors(errors)
                })
        },

        OLDsaveGuest( ) {
            if (this.host_data.rezervace_host_id == null) {
                mutations.createHost(this.host_data)
                    .then(/*response*/ ( ) => {
                        createNotice(this.$t("Úspěšně uloženo"))

                        this.$refs['modal-guest-detail'].hide( )

                        mutations.readRezervace(state.request_rezervace_id)
                    })
                    .catch(errors => {
                        mutations.processHostDataErrors(errors)
                    })
            } else {
                mutations.updateHost(this.host_data)
                    .then(/*response*/ ( ) => {
                        createNotice(this.$t("Úspěšně uloženo"))

                        this.$refs['modal-guest-detail'].hide( )

                        mutations.readRezervace(state.request_rezervace_id)
                    })
                    .catch(errors => {
                        mutations.processHostDataErrors(errors)
                    })
            }
        }
    },

    mounted( ) {
        // Test na Id rezervace z parametru požadavku
        //console.log(this.$route.params)

        if ("id" in this.$route.params) {
            let rezervaceIdHash = this.$route.params.id

            mutations.setRequestRezervaceId(rezervaceIdHash)

            mutations.readRezervace(rezervaceIdHash)
        }
    }
}
</script>