<template>
    <div>
        <div class="card text-dark bg-light">
            <div class="card-body">
                <form class="row g-3">
                    <div class="col-md-4">
                        <b-form-group
                            :label="$t('Jméno')"
                            label-for="jmeno"
                            label-align="left"
                            >
                                <b-form-input
                                    id="jmeno"
                                    v-model="host_data.jmeno">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('jmeno')">
                                    {{ host_data_errors.jmeno }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-4">
                        <b-form-group
                            :label="$t('Příjmení')"
                            label-for="prijmeni"
                            label-align="left"
                            >
                                <b-form-input
                                    id="prijmeni"
                                    v-model="host_data.prijmeni">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('prijmeni')">
                                    {{ host_data_errors.prijmeni }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-4">
                        <b-form-group
                            :label="$t('Ulice')"
                            label-for="ulice"
                            label-align="left"
                            >
                                <b-form-input
                                    id="ulice"
                                    v-model="host_data.ulice">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('ulice')">
                                    {{ host_data_errors.ulice }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <!--<div class="col-md-3">
                        <b-form-group
                            label="Číslo popisné"
                            label-for="cislo_popisne"
                            label-align="left"
                            >
                                <b-form-input
                                    id="cislo_popisne"
                                    v-model="host_data.cislo_popisne">
                                </b-form-input>
                        </b-form-group>
                    </div>-->
                    <div class="col-md-4">
                        <b-form-group
                            :label="$t('Město')"
                            label-for="mesto"
                            label-align="left"
                            >
                                <b-form-input
                                    id="mesto"
                                    v-model="host_data.mesto">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('mesto')">
                                    {{ host_data_errors.mesto }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-4">
                        <b-form-group
                            :label="$t('PSČ')"
                            label-for="psc"
                            label-align="left"
                            >
                                <b-form-input
                                    id="psc"
                                    v-model="host_data.psc">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('psc')">
                                    {{ host_data_errors.psc }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-4">
                        <b-form-group
                            :label="$t('Země')"
                            label-for="zeme"
                            label-align="left"
                            >
                                <b-form-select
                                    :options="rezervace_data.zeme"
                                    text-field="nazev"
                                    value-field="zeme_id"
                                    v-model="host_data.zeme_id"
                                >
                                </b-form-select>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('zeme_id')">
                                    {{ host_data_errors.zeme_id }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-4">
                        <b-form-group
                            :label="$t('Email')"
                            label-for="email"
                            label-align="left"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="host_data.email">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('email')">
                                    {{ host_data_errors.email }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-4">
                        <b-form-group
                            :label="$t('Telefon')"
                            label-for="tel"
                            label-align="left"
                            >
                                <b-form-input
                                    id="tel"
                                    v-model="host_data.tel">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('tel')">
                                    {{ host_data_errors.tel }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <!-- OBSOLETE
                    <div class="col-md-4">
                        <b-form-group
                            :label="$t('Datum narození')"
                            label-for="d_narozeni"
                            label-align="left"
                            >
                                <b-form-datepicker
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
                                    id="d_narozeni"
                                    v-model="host_data.d_narozeni"
                                    :locale="datepicker_d_narozeni.locale"
                                    placeholder=""
                                    :start-weekday="datepicker_d_narozeni.weekday"
                                >
                                </b-form-datepicker>
                                
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('d_narozeni')">
                                    {{ host_data_errors.d_narozeni }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    -->

                    <div class="col-md-4">
                        <b-form-group
                            :label="$t('Datum narození')"
                            label-for="d_narozeni"
                            label-align="left"
                            >
                                <b-form-input
                                    id="d_narozeni"
                                    v-model="host_data.d_narozeni"
                                    :placeholder="$t('Datum formát')">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('d_narozeni')">
                                    {{ host_data_errors.d_narozeni }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>




                    <!--<div class="col-md-3">
                        <b-form-group
                            label="Typ průkazu"
                            label-for="typ_prukazu"
                            label-align="left"
                            >
                                <b-form-input
                                    id="typ_prukazu"
                                    v-model="host_data.typ_prukazu">
                                </b-form-input>
                        </b-form-group>
                    </div>-->
                    <div class="col-md-6">
                        <b-form-group
                            :label="$t('Číslo průkazu')"
                            label-for="cislo_dokladu"
                            label-align="left"
                            >
                                <b-form-input
                                    id="cislo_dokladu"
                                    v-model="host_data.cislo_dokladu">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('cislo_dokladu')">
                                    {{ host_data_errors.cislo_dokladu }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            :label="$t('Číslo víza')"
                            label-for="cislo_viza"
                            label-align="left"
                            >
                                <b-form-input
                                    id="cislo_viza"
                                    v-model="host_data.cislo_viza">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('cislo_viza')">
                                    {{ host_data_errors.cislo_viza }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            :label="$t('Účel pobytu')"
                            label-for="ucel_pobytu"
                            label-align="left"
                            >
                                <b-form-select
                                    :options="rezervace_data.ucel_pobytu"
                                    text-field="nazev"
                                    value-field="ucel_pobytu_id"
                                    v-model="host_data.ucel_pobytu_id"
                                >
                                </b-form-select>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('ucel_pobytu_id')">
                                    {{ host_data_errors.ucel_pobytu_id }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            :label="$t('Státní příslušnost')"
                            label-for="statni_prislusnost"
                            label-align="left"
                            >
                                <b-form-select
                                    :options="rezervace_data.zeme"
                                    text-field="nazev"
                                    value-field="zeme_id"
                                    v-model="host_data.statni_prislusnost"
                                >
                                </b-form-select>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('statni_prislusnost')">
                                    {{ host_data_errors.statni_prislusnost }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-12">
                        <b-form-group
                            :label="$t('Pokoj')"
                            label-for="pokoj"
                            label-align="left"
                            >
                                <b-form-select
                                    :options="rezervace_data.pokoje"
                                    text-field="nazev"
                                    value-field="pokoj_id"
                                    disabled-field="nenabizet"
                                    v-model="host_data.pokoj_id"
                                >
                                </b-form-select>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('pokoj_id')">
                                    {{ host_data_errors.pokoj_id }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-12">
                        <b-form-group
                            :label="$t('Poznámka')"
                            label-for="poznamka"
                            label-align="left"
                            >
                                <b-form-input
                                    id="poznamka"
                                    v-model="host_data.poznamka">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('poznamka')">
                                    {{ host_data_errors.poznamka }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                
                    <!--<div class="col-md-12">
                        <b-button pill size="sm" variant="primary">Uložit</b-button>&nbsp;
                        <b-button pill size="sm" variant="secondary">Zrušit</b-button>
                    </div>-->
                    
                    <!--
                    <div class="col-md-6">
                        <label for="inputPassword4" class="form-label">Password</label>
                        <input type="password" class="form-control" id="inputPassword4">
                    </div>
                    <div class="col-12">
                        <label for="inputAddress" class="form-label">Address</label>
                        <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St">
                    </div>
                    <div class="col-12">
                        <label for="inputAddress2" class="form-label">Address 2</label>
                        <input type="text" class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor">
                    </div>
                    <div class="col-md-6">
                        <label for="inputCity" class="form-label">City</label>
                        <input type="text" class="form-control" id="inputCity">
                    </div>
                    <div class="col-md-4">
                        <label for="inputState" class="form-label">State</label>
                        <select id="inputState" class="form-select">
                        <option selected>Choose...</option>
                        <option>...</option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <label for="inputZip" class="form-label">Zip</label>
                        <input type="text" class="form-control" id="inputZip">
                    </div>
                    <div class="col-12">
                        <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck">
                        <label class="form-check-label" for="gridCheck">
                            Check me out
                        </label>
                        </div>
                    </div>
                    <div class="col-12">
                        <button type="submit" class="btn btn-primary">Sign in</button>
                    </div>
                    -->
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { state/*, mutations*/ } from '@/store/index.js'
//import storage from '@/lib/gms/storage.js'

export default {
    components: { },

    computed: {
        host_data( ) {
            return state.host_data
        },

        host_data_errors( ) {
            return state.host_data_errors
        },

        /*locale( ) {
            let g11n = storage.getItem("g11n")

            // Vrátí locale ze storage, pokud je nastaveno
            if (g11n != null) return g11n.locale

            return this.$i18n.locale
        },*/

        rezervace_data( ) {
            return state.rezervace_data
        }
    },

    data( ) {
        return {
            datepicker_d_narozeni: {
                locale: state.g11n.locale,
                weekday: 1
            }
        }
    },

    methods: {
        fieldIsInvalid(field) {
            //console.log(state.host_data_errors[field])
            return state.host_data_errors[field] != null
        }
    },

    mounted( ) { }
}
</script>